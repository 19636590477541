<template>
    <CRow>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars"/>
        <CCol sm="12">
            <CCollapse :show="!collapseVisits">
                <CCard>
                    <CCardHeader class="text-center bg-dark text-white">
                        <b>{{$t('label.visits')}}</b>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12">
                                <appCalendar />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCollapse>
            <CCollapse :show="collapseVisits">
                <div  v-if="collapseVisits"><appMenu /></div>
            </CCollapse>
        </CCol>
    </CRow>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import appCalendar from './calendar-visita';
    import appMenu from './menu-visita';
    //computed
    function apiStateLoading() {
        let carga = false;
        if(this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    //watch
    function apiState(newQuestion,OldQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' :  this.messageError)
                });
                this.$store.state.visitas.messageError = '';
            }
        }
    }
    function apiStateForm(newQuestion,OldQuestion){
        switch (newQuestion) {
            case ENUM.ERROR:
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? 'Se ha producido un error inesperado' : this.messageError)
                });
                this.$store.state.visitas.messageError = '';
                break;
            case ENUM.LOADED:
                this.$notify({
                    group: 'container',
                    type: 'success',
                    ignoreDuplicates:false,
                    title: '¡Éxito!',
                    text: (this.messageError === '' ? 'Su proceso ha finalizado con éxito' : this.messageError)
                });
                this.$store.state.visitas.messageError = '';
                break;
            default:
                break;
        }
    }
    export default {
        name:'visitas-insumo',
        components: {
            appCalendar,
            appMenu
        },
        computed:{
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                messageError : state=> state.visitas.messageError,
                apiState: state => state.visitas.apiState,
                apiStateForm: state => state.visitas.apiStateForm,
                collapseVisits: state => state.visitas.collapseVisits
            })
        },
        watch:{
            apiState,
            apiStateForm,
        }
    }
</script>